import {useState} from "react";
import store from '../../redux/store';

const useApiClient = (url, fetchOptions) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(undefined);

  const states = {isLoading, response};

  const defaultFetchOptions = {
    method: 'GET',
    headers: {},
    credentials: 'omit'
  };

  fetchOptions = {
    ...defaultFetchOptions,
    ...fetchOptions
  }

  const call = (data= null) => {
    const state = store.getState();
    if(!state.auth.isLoading && state.auth.authToken != null) {
      fetchOptions.headers = {
        ...fetchOptions.headers,
        Authorization: `${state.auth.authToken}`
      }
    }

    if(data) {
      switch (fetchOptions.method){
        case 'POST':
          fetchOptions.body = JSON.stringify(data);
          break;
        case 'GET':
          // todo
          break;
        default:
          break;
      }
    }
    setIsLoading(true);

    return fetch(url, fetchOptions)
      .then(res => res.json())
      .then(res => CheckResponse(res))
      .catch(err => ({payload: undefined, error: err.message}))
      .then(res => {
        setIsLoading(false);
        setResponse(res);
        return res;
      });
  }

  const [callState] = useState(() => call);

  return {
    callApi: callState,
    states
  };
}


export const CheckResponse = (res) => {
  let obj = {
    payload: undefined,
    error: undefined
  }

  if(res.statusCode === 200){
    obj.payload = res.data ?? 'success';
  }
  else{
    obj.error = res.status;
  }

  return obj;
}

export default useApiClient;
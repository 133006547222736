import apiClient from './useApiClient';
import {LocalDevelopmentApiUrl as BaseUrl} from './apiConstants';

export const useUserTestClient = () => apiClient(`${BaseUrl}/User/Test`);

export const useLoginClient = () => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: 'POST',
    headers: myHeaders
  };

  return apiClient(`${BaseUrl}/User/Login`, requestOptions);
}

export const useRegisterClient = () => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: 'POST',
    headers: myHeaders
  };

  return apiClient(`${BaseUrl}/User/Register`, requestOptions);
}
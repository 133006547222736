import '../../index.less'
import {useHistory} from "react-router-dom";
import Layout, {Content, Footer, Header} from "antd/es/layout/layout";
import ButtonLong from "../../sharedComponents/ButtonLong";
import {Card, Col, Row, Statistic} from "antd";
import React, {useEffect, useState} from "react";
import useApiClient from "../../helper/api/useApiClient";
import {LocalDevelopmentApiUrl as BaseUrl} from "../../helper/api/apiConstants";
import DynamicColorStatistics from "../../sharedComponents/DynamicColorStatistics";

const StatisticsPage = () => {

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const gameId = queryParams.get('id');

    const [getGameStatistics, setGameStatistics] = useState(undefined);
    const getGameStatisticsClient = useApiClient(`${BaseUrl}/statistic/getstatisticsforgame?gameId=`.concat(gameId));

    useEffect(() => {
        getGameStatisticsClient.callApi().then(res => {
            if (res.error) {
                // TODO Error Handling
            } else {
                setGameStatistics(res.payload);
            }
        });
    }, []);

    const goBack = () =>{
        history.push('/quiz?id='.concat(getGameStatistics.quizId));
    }

    return (
        <Layout>
            <Header className={'height64 width100'}>
                <div className={'height100 flex-center redBackground'}>
                    <h1 className={'textaligncenter header-no-margin fontwhite'}>{getGameStatistics && getGameStatistics.name}</h1>
                </div>
            </Header>
            <br/>
            <Content className={'padding10'}>
                <div className={'statictics'}>
                    <div className="site-statistic-demo-card">
                        <Row gutter={16}>
                            <Col span={12} className={'paddingstatistics stats-card'}>
                                <Card className={'statisticsheight'}>
                                    <Statistic
                                        title="Practised Questions"
                                        value={getGameStatistics && getGameStatistics.playedCardsCount}
                                        valueStyle={{ color: '#3f8600' }}
                                    />
                                </Card>
                            </Col>
                            <Col span={12} className={'paddingstatistics stats-card'}>
                                <Card className={'statisticsheight'}>
                                    <Statistic
                                        title="Completion rate"
                                        value={getGameStatistics && (getGameStatistics.playedCardsCount / getGameStatistics.allCardsCount * 100)}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        suffix="%"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-statistic-demo-card">
                        <Row gutter={16}>
                            <Col span={12} className={'paddingstatistics stats-card'}>
                                <Card className={'statisticsheight'}>
                                    <DynamicColorStatistics value={getGameStatistics && (getGameStatistics.correctCount / getGameStatistics.playedCardsCount * 100)}
                                                            title={"Accuracy"}/>
                                </Card>
                            </Col>
                            <Col span={12} className={'paddingstatistics stats-card'}>
                                <Card className={'statisticsheight'}>
                                    <DynamicColorStatistics value={getGameStatistics && (getGameStatistics.correctCountLast5Games / getGameStatistics.cardCountLast5Games * 100)}
                                                            title={"Average (last 5 games)"}/>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>

            <Footer className={'height50'}>
                <ButtonLong title='Go back to Quiz Overview' onClick={goBack}/>
            </Footer>

        </Layout>
    );
}

export default StatisticsPage;
import {Button, Col, Input, Row} from "antd";
import {Form} from 'antd';
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";

const QuestionAnswer = ({cards=undefined}) => {
    return (
        <Form.List initialValue={cards} name="cards">
            {(fields, { add, remove }) => {
                return (
                    <div>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                <Row style={{paddingBottom: '24px'}}>
                                    <Col span={20}>
                                        <Form.Item
                                            name={[index, "question"]}
                                            rules={[{ required: true }]}
                                            style={{marginBottom: 0}}
                                        >
                                            <Input placeholder="Question" autoComplete='off' />
                                        </Form.Item>
                                        <Form.Item
                                            name={[index, "answer"]}
                                            rules={[{ required: true }]}
                                            style={{marginBottom: 0}}
                                        >
                                            <Input placeholder="Answer" autoComplete='off' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Button
                                            className="dynamic-delete-button"
                                            style={{height: '100%', width: '100%', background: '#FB3640', border: 'none'}}
                                            onClick={() => remove(field.name)}
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: "100%" }}
                            >
                                <PlusOutlined /> Add Question
                            </Button>
                        </Form.Item>
                    </div>
                );
            }}
        </Form.List>
    );
}

export default QuestionAnswer;

import {Statistic} from "antd";
import React from "react";

const DynamicColorStatistics = ({value, title}) => {
    let color = '#3f8600';
    if (value < 50) {
        color = '#FB3640';
    } else if (value < 80) {
        color = '#EDD83D';
    }

    if (value == null || isNaN(value)) {
        value = '-';
        color = 'white';
    }

    return <Statistic
        title={title}
        value={value}
        precision={2}
        valueStyle={{color: color}}
        suffix={value === "-" ? "" : "%"}
    />;
}

export default DynamicColorStatistics;
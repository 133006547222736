import React, {useEffect, useState} from "react";
import QuizContainer from './components/QuizContainer';
import AddButton from '../../sharedComponents/AddButton';
import SearchBar from '../../sharedComponents/SearchBar';
import ButtonLong from '../../sharedComponents/ButtonLong';
import {useHistory} from "react-router-dom";
import Layout, {Content, Footer, Header} from "antd/es/layout/layout";
import useApiClient from "../../helper/api/useApiClient";
import {LocalDevelopmentApiUrl as BaseUrl} from "../../helper/api/apiConstants";
import {Result} from "antd";
import {CloseCircleFilled} from '@ant-design/icons';
import './components/cardStyles.less';
import LogoutButton from "../../sharedComponents/LogoutButton";
import {connect} from "react-redux";
import {clearAuth} from "../../redux/actions/authActions";

const OverviewPage = ({clearAuth}) => {
    const [apiResultQuiz, setApiResultQuiz] = useState(undefined);
    const getAllQuizForUser = useApiClient(`${BaseUrl}/quiz/getallquizforuser`);
    const history = useHistory();

    useEffect(() => {
        getAllQuizForUser.callApi().then(res => setApiResultQuiz(res));
    }, []);

    const addQuiz = () => {
        history.push('/addQuiz');
    }

    const [getSearchTerm, setSearchTerm] = useState('');

    const QuizCards = () => {
        if (apiResultQuiz == null) {
            return null;
        } else if (apiResultQuiz.error) {
            return (<Result
                icon={<CloseCircleFilled className={"primary-color"}/>}
                status="error"
                title="Failed to load"
                subTitle={apiResultQuiz.error}

            />);
        }

        return apiResultQuiz.payload
            .filter(quiz => quiz.name.toLowerCase().includes(getSearchTerm.toLowerCase()))
            .map(quiz => {
                return (<QuizContainer key={quiz.id} quizTitle={quiz.name} count={quiz.numCard}
                                       route={'/quiz?id='.concat(quiz.id)}/>);
            })
    }

    const onLogout = () => {
        clearAuth();
    }

    return (
        <Layout>
            <Header className={'redBackground height64 flex-center'}>
                <div className={'inlineBlock width20 height100'}>
                    <LogoutButton onClick={onLogout}/>
                </div>
                <div className={'inlineBlock width80'}>
                    <SearchBar onSearch={(event) => setSearchTerm(event.target.value)}/>
                </div>
                <div className={'inlineBlock width20 height100'}>
                    <AddButton onClick={addQuiz}/>
                </div>
            </Header>
            <Content className={'padding10'}>
                <QuizCards/>
            </Content>

            <Footer className={'height50'}>
                <ButtonLong/>
            </Footer>
        </Layout>
    );
}

const mapDispatchToProps = (dispatch) => ({
    clearAuth: () => {
        localStorage.removeItem('user');
        return dispatch(clearAuth());
    }
});

export default connect(null, mapDispatchToProps)(OverviewPage);

import {Button} from "antd";
import './less/buttonLong.less';
import '../index.less';

const ButtonLong = ({onClick, title, ...rest}) => {
    return (
        <div className={'height100'}>
            <Button {...rest} onClick={onClick} className={'buttonlong height100'} htmlType='submit'>{title}</Button>
        </div>
    );
}

export default ButtonLong;
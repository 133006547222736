import {Form, Input, Button, Card, Switch, Alert} from 'antd';
import {UserOutlined, LockOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import './login.less';
import useNavigateTo from '../../helper/useNavigateTo';
import {useLoginClient} from '../../helper/api/apiDefaultClients';
import {useState} from 'react';
import {connect} from 'react-redux';
import {authenticate} from '../../redux/actions/authActions';

const LoginPage = ({setAuthState}) => {
  const {callApi: callLogin, states: loginStates} = useLoginClient();
  const [submitError, setSubmitError] = useState();

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    callLogin(values).then(res => {
      if(res.error) {
        setSubmitError(res.error);
      }
      else {
        setAuthState(res.payload);
      }
    });
  };

  return (
    <div id='login-from-wrapper'>
      <Card title='Login'>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          {
            submitError &&
            <Alert
              message={submitError}
              type="error"
              closable
              style={{marginBottom: '18px'}}
            />
          }
          <Form.Item
            name='username'
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              size='large'
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Username'
              autoComplete='off'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              size='large'
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Password'
              autoComplete='off'
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name='rememberMe' valuePropName='checked' initialValue={true} label='Remember me' labelCol={2} className='ant-form-switch'>
              <Switch
                size='large'
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item >
            <Button size='large' type='primary' htmlType='submit' className='login-form-button' loading={loginStates.isLoading}>
              Log in
            </Button>
          </Form.Item>
        </Form>
        <Button size='large' type='ghost' className='login-form-button' onClick={useNavigateTo('register')}  >
          Register Now
        </Button>
      </Card>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAuthState: (userInformation) => {
    window.localStorage.setItem("user", JSON.stringify(userInformation));
    return dispatch(authenticate(userInformation));
  }
});

export default connect(null, mapDispatchToProps)(LoginPage);
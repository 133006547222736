import {Button} from "antd";
import { DoubleLeftOutlined } from '@ant-design/icons';
import '../index.less'

const ReturnButton = ({onClick}) => {
    return (
        <div className={'height100 width100'}>
            <Button onClick={onClick} type="text" shape={"circle"} className={'height100'} icon={<DoubleLeftOutlined className={'font25'}/>}/>
        </div>
    );
}

export default ReturnButton;
import {Input, Space} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import './less/searchBar.less';
import '../index.less';

const SearchBar = ({onSearch}) => {
    return (
        <div className='redBackground'>
            <Space className='searchbar' direction="vertical">
                <Input onChange={onSearch} size="large" placeholder=" Search for ..." prefix={<SearchOutlined />} className='brightBlackBackground fontwhite' />
            </Space>
        </div>
    );
}

export default SearchBar;
import {Button} from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import '../index.less';
import React from "react";

const DeleteButton = ({onClick}) => {
    return (
        <div className={'height100 width100'}>
            <Button onClick={onClick} type="text" shape={"circle"} className={'height100 floatright'} icon={<DeleteOutlined className={'font25'} />}/>
        </div>
    );
}

export default DeleteButton;
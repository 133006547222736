import ReturnButton from "../../sharedComponents/ReturnButton";
import {Form, Input, Layout, Space} from "antd";
import ButtonLong from "../../sharedComponents/ButtonLong";
import {useHistory} from "react-router-dom";
import QuestionAnswer from "../../sharedComponents/QuestionAnswer";
import {Content, Header} from "antd/es/layout/layout";
import '../../index.less'
import React, {useState} from "react";
import useApiClient from "../../helper/api/useApiClient";
import {LocalDevelopmentApiUrl as BaseUrl} from "../../helper/api/apiConstants";

const AddQuiz = () => {
    let requestOptions = {
        method: 'POST',
        headers: {
            "content-type": "application/json"
        }
    };
    const [, setSubmitError] = useState();
    const createQuizClient = useApiClient(`${BaseUrl}/quiz/createquiz`, requestOptions);
    const history = useHistory();

    const goBack = () => {
        history.push('/overview');
    }

    const onSubmit = (values) => {
        values.created = values.cards;
        delete values.cards;
        createQuizClient.callApi(values).then(res => {
            if (res.error){
                setSubmitError(res.error);
            }
            else {
                history.push('/quiz/'.concat(res.payload.id));
            }
        });
    }

    return (
        <Layout>
            <Header className={'height64'}>
                <div className={'inlineBlock width100 height100 redBackground'}>
                    <ReturnButton onClick={goBack}/>
                    <br/>
                </div>
            </Header>
            <br/>
            <Content>
                <Form onFinish={onSubmit}>
                    <div className={'padding10'}>
                        <h1 className='fontwhite'>Title</h1>
                        <Form.Item
                            name="name"
                            rules={[{required: true}]}
                        >
                            <Input placeholder="Title" autoComplete='off'/>
                        </Form.Item>
                    </div>
                    <Space className='searchbar' direction="vertical">
                        <h1 className='fontwhite'>Questions</h1>
                        <QuestionAnswer/><br/>
                    </Space>
                    <div className={'height50 footer'}>
                    <ButtonLong title='CREATE'/>
                    </div>
                </Form>
            </Content>
        </Layout>
    );
}

export default AddQuiz;
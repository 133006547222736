import {Button} from "antd";
import { CheckOutlined } from '@ant-design/icons';
import './less/checkButton.less';

const CheckButton = ({onClick, ...props}) => {
    return (
        <div className={'button-focus-green'}>
            <Button {...props} type="secondary" onClick={onClick} className='blackOutline height60' icon={<CheckOutlined className='checkButton font25'/>}/>
        </div>
    );
}

export default CheckButton;
import ReturnButton from "../../sharedComponents/ReturnButton";
import {Form, Input, Layout, Space} from "antd";
import ButtonLong from "../../sharedComponents/ButtonLong";
import QuestionAnswer from "../../sharedComponents/QuestionAnswer";
import {Content, Header} from "antd/es/layout/layout";
import '../../index.less'
import React, {useState} from "react";
import useApiClient from "../../helper/api/useApiClient";
import {LocalDevelopmentApiUrl as BaseUrl} from "../../helper/api/apiConstants";

const EditQuiz = ({quizId, title, cards, endEdit}) => {
    let requestOptions = {
        method: 'POST',
        headers: {
            "content-type": "application/json"
        }
    };
    const [, setSubmitError] = useState();
    const editQuizClient = useApiClient(`${BaseUrl}/quiz/editquiz`, requestOptions);

    const onSubmit = (values) => {
        const created = values.cards.filter(card => card.cardId == null);
        const updated = values.cards
            .filter(val => val.cardId != null)
            .filter(val => cards.some(card => card.cardId === val.cardId && (card.question !== val.question || card.answer !== val.answer)));
        const deleted = cards
            .filter(card => !values.cards.some(val => val.cardId === card.cardId))
            .map(val => ({cardId: val.cardId}));

        editQuizClient.callApi({
            quizId: quizId,
            name: values.title,
            editCards : {
                created: created,
                updated: updated,
                deleted: deleted
            }
        }).then(res => {
            if (res.error){
                setSubmitError(res.error);
            }
            else {
                endEdit();
            }
        });
    }

    return (
        <Layout>
            <Header className={'height64'}>
                <div className={'inlineBlock width100 height100 redBackground'}>
                    <ReturnButton onClick={endEdit}/>
                    <br/>
                </div>
            </Header>
            <br/>
            <Content>
                <Form onFinish={onSubmit}>
                    <div className={'padding10'}>
                        <h1 className='fontwhite'>Title</h1>
                        <Form.Item
                            name="title"
                            rules={[{ required: true }]}
                            initialValue={title}
                        >
                            <Input placeholder="Title" autoComplete='off' />
                        </Form.Item>
                    </div>
                    <Space className='searchbar' direction="vertical">
                        <h1 className='fontwhite'>Questions</h1>
                        <QuestionAnswer cards={cards}/><br/>
                    </Space>
                    <div className={'height50 footer'}>
                        <ButtonLong title='SAVE'/>
                    </div>
                </Form>
            </Content>
        </Layout>
    );
}

export default EditQuiz;
import '../../index.less'
import './index.less'
import DynamicColorStatistics from '../../sharedComponents/DynamicColorStatistics';
import {useHistory} from "react-router-dom";
import Layout, {Content, Footer, Header} from "antd/es/layout/layout";
import ReturnButton from "../../sharedComponents/ReturnButton";
import ButtonLong from "../../sharedComponents/ButtonLong";
import EditButton from "../../sharedComponents/EditButton";
import EditQuiz from "../QuizEdit";
import React, {useEffect, useState} from "react";
import useApiClient from "../../helper/api/useApiClient";
import {LocalDevelopmentApiUrl as BaseUrl} from "../../helper/api/apiConstants";
import SearchBar from "../../sharedComponents/SearchBar";
import QuestionAnswerNoChanges from "../../sharedComponents/QuestionAnswerNoChanges";
import {Statistic, Card, Row, Col, Modal} from 'antd';
import DeleteButton from "../../sharedComponents/DeleteButton";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const QuizOverview = () => {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const quizId = queryParams.get('id');

    const onGoBack = () => {
        history.push('/overview');
    }

    const [getQuizResult, setQuizResult] = useState(undefined);
    const [editMode, setEditMode] = useState(false);
    const getQuizClient = useApiClient(`${BaseUrl}/quiz/getquizoverview/?quizId=`.concat(quizId));
    useEffect(() => {
        getQuizClient.callApi().then(res => setQuizResult(res.payload));
    }, [editMode]);

    let requestOptions = {
        method: 'POST',
        headers: {
            "content-type": "application/json"
        }
    };
    const [, setGameResultError] = useState(undefined);
    const createGameClient = useApiClient(`${BaseUrl}/quiz/creategame`, requestOptions);
    const onStart = () => {
        createGameClient.callApi({'QuizId': quizId}).then(res => {
            if (res.error) {
                setGameResultError(res.error);
            } else {
                history.push('/game?quizId='
                    .concat(quizId)
                    .concat('&gameId=')
                    .concat(res.payload)
                    .concat('&title=')
                    .concat(getQuizResult.name));
            }
        });
    }

    const [getSearchTerm, setSearchTerm] = useState('');

    function Cards() {
        if (!getQuizResult) {
            return null;
        }
        return getQuizResult.cards
            .filter(card => card.question.toLowerCase().includes(getSearchTerm.toLowerCase())
                || card.answer.toLowerCase().includes(getSearchTerm.toLowerCase()))
            .map((card, index) => {
                return (<QuestionAnswerNoChanges key={card.cardId} question={card.question} answer={card.answer}
                                                 stat={Math.round(card.correctCount / card.playCount * 100)}/>);
            })
    }

    const {confirm} = Modal;
    const deleteQuizClient = useApiClient(`${BaseUrl}/quiz/deletequiz/?quizId=`.concat(quizId), {method: "DELETE"});

    function onDeleteQuiz() {
        confirm({
            title: 'Are you sure you want to delete this quiz?',
            icon: <ExclamationCircleOutlined/>,
            content: 'This change cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteQuizClient.callApi().then(res => {
                    if (res.error != null) {
                        console.log(res.error);
                    } else {
                        history.push('/overview');
                    }
                })
            },
        });
    }

    return (editMode ?
            <EditQuiz quizId={quizId}
                      title={getQuizResult && getQuizResult.name}
                      cards={getQuizResult && getQuizResult.cards}
                      endEdit={() => {
                          setEditMode(false)
                      }}/> :
            <Layout>
                <Header className={'height64 width100'}>
                    <div className={'height100 redBackground'}>
                        <div className={'width50 buttonleft height100'}>
                            <ReturnButton onClick={onGoBack}/>
                        </div>
                        <div className={'width50 buttonright height100 flex'}>
                            <DeleteButton onClick={onDeleteQuiz}/>
                            <EditButton onClick={() => setEditMode(true)}/>
                        </div>
                    </div>
                </Header>
                <Content className={'padding-top-15'}>
                    <div className={'padding10'}>
                    <h1 className='fontwhite textaligncenter'>{getQuizResult && getQuizResult.name}</h1>
                    <div className={'statictics'}>
                        <div className="site-statistic-demo-card">
                            <Row gutter={16}>
                                <Col span={12} className={'paddingstatistics'}>
                                    <Card className={'statisticsheight'}>
                                        <Statistic
                                            title="Questions"
                                            value={getQuizResult && getQuizResult.stats.cardCount}
                                            valueStyle={{color: '#3f8600'}}
                                        />
                                    </Card>
                                </Col>
                                <Col span={12} className={'paddingstatistics'}>
                                    <Card className={'statisticsheight'}>
                                        <Statistic
                                            title="Practices"
                                            value={getQuizResult && getQuizResult.stats.playCount}
                                            valueStyle={{color: '#3f8600'}}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div className="site-statistic-demo-card">
                            <Row gutter={16}>
                                <Col span={12} className={'paddingstatistics'}>
                                    <Card className={'statisticsheight'}>
                                        <DynamicColorStatistics value={getQuizResult && (getQuizResult.stats.cardCountCorrectAll / getQuizResult.stats.cardCountAll * 100)}
                                                                title={"Accuracy"}/>
                                    </Card>
                                </Col>
                                <Col span={12} className={'paddingstatistics'}>
                                    <Card className={'statisticsheight'}>
                                        <DynamicColorStatistics value={getQuizResult && (getQuizResult.stats.cardCountCorrectLast5Games / getQuizResult.stats.cardCountLast5Games * 100)}
                                                                title={"Accuracy last 5 games"}/>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <br/>
                    <h3 className='fontwhite textaligncenter'>QUESTIONS</h3>
                    </div>
                    <SearchBar onSearch={(event) => {
                        setSearchTerm(event.target.value)
                    }}/>
                    <div className={'padding10'}>
                    <Cards/>
                    </div>
                </Content>

                <Footer className={'height50'}>
                    <ButtonLong title='START' onClick={onStart} disabled={getQuizResult && getQuizResult.stats.cardCount < 1}/>
                </Footer>

            </Layout>
    );
}

export default QuizOverview;
import '../../index.less'
import {useHistory} from "react-router-dom";
import Layout, {Content, Footer, Header} from "antd/es/layout/layout";
import ButtonLong from "../../sharedComponents/ButtonLong";
import CheckButton from "../../sharedComponents/CheckButton";
import WrongButton from "../../sharedComponents/WrongButton";
import React, {useEffect, useState} from 'react';
import ReactCardFlip from "react-card-flip";
import useApiClient from "../../helper/api/useApiClient";
import {LocalDevelopmentApiUrl as BaseUrl} from "../../helper/api/apiConstants";
import {Spin} from 'antd';

const GamePage = () => {

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const gameId = queryParams.get('gameId');
    const quizId = queryParams.get('quizId');
    const title = queryParams.get('title');

    const [getCards, setCards] = useState(undefined);
    const [, setError] = useState(undefined);
    const [getSpinLoading, setSpinLoading] = useState(false);
    const getCardsClient = useApiClient(`${BaseUrl}/quiz/getcardsforquizrandom?QuizId=`.concat(quizId));

    useEffect(() => {
        getCardsClient.callApi().then(res => {
            if (res.error) {
                setError(res.error);
            } else {
                setCards(res.payload);
            }
        });
    }, []);

    let requestOptions = {
        method: 'POST',
        headers: {
            "content-type": "application/json"
        }
    };
    const [getCardIteration, setCardIteration] = useState(0);
    const addStatisticClient = useApiClient(`${BaseUrl}/statistic/addstatistic`, requestOptions);

    async function onFinishCard(isCorrect) {
        setIsFlipped(false);

        setSpinLoading(true);
        setTimeout(() => setSpinLoading(false), 400);

        addStatisticClient.callApi({
            "gameId": gameId,
            "cardId": getCards[getCardIteration].id,
            "correct": isCorrect
        }).then(res => {
            if (res.error) {
                setError(res.error);
                return;
            }

            if (getCardIteration + 1 < getCards.length) {
                setCardIteration(getCardIteration + 1);
            } else {
                onEndGame();
            }
        });
    }

    const isClientLoading = () => {
        return addStatisticClient.states.isLoading || getCardsClient.states.isLoading || getSpinLoading;
    }

    const onEndGame = () => {
        history.push('/statistic?id='.concat(gameId));
    }

    const [isFlipped, setIsFlipped] = useState(false);

    return (
        <Layout>
            <Header className={'height64 width100'}>
                <div className={'height100 flex-center redBackground'}>
                    <h1 className={'textaligncenter header-no-margin fontwhite'}>{title}</h1>
                </div>
            </Header>
            <br/>
            <Content className={'padding10'}>
                <div className={'cardcenter'}>
                    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                        <div onMouseDownCapture={() => setIsFlipped((prev) => !prev)}
                             className="CardFront cardfront padding-top-20">
                            <h3>Question</h3>
                            {isClientLoading() ? <Spin/> : <p>{getCards && getCards[getCardIteration].question}</p>}
                        </div>
                        <div onMouseDownCapture={() => setIsFlipped((prev) => !prev)}
                             className="CardBack cardback padding-top-20">
                            <h3>Answer</h3>
                            {isClientLoading() ? <Spin/> : <p>{getCards && getCards[getCardIteration].answer}</p>}
                        </div>
                    </ReactCardFlip>
                </div>
                <br/>
                <div className={'textaligncenter'}>
                    <div className={'height100 questionWrongRight'}>
                        <div className={'width50 buttonleft height100'}>
                            <CheckButton loading={isClientLoading()} onClick={() => onFinishCard(true)}/>
                        </div>
                        <div className={'width50 buttonright height100'}>
                            <WrongButton loading={isClientLoading()} onClick={() => onFinishCard(false)}/>
                        </div>
                    </div>
                </div>
            </Content>
            <Footer className={'height50'}>
                <ButtonLong title='End Game' onClick={onEndGame}/>
            </Footer>

        </Layout>
    );
}

export default GamePage;
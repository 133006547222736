// when user
export const login = (userData) => ({
  type: 'LOGIN',
  payload: {
    isLoading: true
  }
});

export const logout = () => ({
  type: 'LOGOUT',
  payload: {
    isLoading: true
  }
});

export const authenticate = (userData) => ({
  type: 'AUTHENTICATE',
  payload: {
    authToken: userData.authToken,
    authenticatedSince: Date.now(),
    authInfo: {
      firstname: userData.userInformation.firstname,
      lastname: userData.userInformation.lastname
    },
    isLoading: false
  }
});

export const clearAuth = (errorMsg = undefined) => ({
  type: 'CLEAR_AUTH',
  payload: {
    isLoading: false,
    errorMsg
  }
});

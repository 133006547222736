import '../index.less'
import { Collapse } from 'antd';

const { Panel } = Collapse;

const QuestionAnswerNoChanges = ({question, answer, stat}) => {

    if (stat == null || isNaN(stat)) {
        stat = '-'
    } else {
        stat += ' %';
    }

    return (
        <Collapse>
            <Panel header={question} extra={<p>{stat}</p>}>
                <p>{answer}</p>
            </Panel>
        </Collapse>
    );
}

export default QuestionAnswerNoChanges;

import {Form, Input, Button, Card, Alert} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';
import './register.less';
import useNavigateTo from '../../helper/useNavigateTo';
import {useRegisterClient} from '../../helper/api/apiDefaultClients';
import {useState} from 'react';

const RegisterPage = () => {
  const {callApi: callRegister, states: registerStates} = useRegisterClient();
  const [submitError, setSubmitError] = useState(null);

  const navToLogin = useNavigateTo('login');

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    callRegister(values).then(res => {
      if(res.error) {
        setSubmitError(res.error)
      }
      else {
        navToLogin();
      }
    });
  };

  return (
    <div id='login-from-wrapper'>
      <Card title='Register'>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          {
            submitError &&
            <Alert
              message={submitError}
              type="error"
              closable
              style={{marginBottom: '18px'}}
            />
          }
          <Form.Item
            name='username'
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              size='large'
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Username'
              onChange={() => {setSubmitError(null);}}
              autoComplete='off'
            />
          </Form.Item>
          <Form.Item
            name='firstname'
            rules={[
              {
                required: true,
                message: 'Please input your Firstname!',
              },
            ]}
          >
            <Input
              size='large'
              prefix={<DoubleRightOutlined className='site-form-item-icon' />}
              placeholder='Firstname'
              autoComplete='off'
            />
          </Form.Item>
          <Form.Item
            name='lastname'
            rules={[
              {
                required: true,
                message: 'Please input your Lastname!',
              },
            ]}
          >
            <Input
              size='large'
              prefix={<DoubleRightOutlined className='site-form-item-icon' />}
              placeholder='Lastname'
              autoComplete='off'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              size='large'
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Password'
              autoComplete='off'
            />
          </Form.Item>

          <Form.Item >
            <Button size='large' type='primary' htmlType='submit' className='login-form-button' loading={registerStates.isLoading}>
              Create Account
            </Button>
          </Form.Item>
        </Form>
        <Button size='large' type='ghost' className='login-form-button' onClick={useNavigateTo('login')}>
          Already have an account?
        </Button>
      </Card>
    </div>
  );
};

export default RegisterPage;
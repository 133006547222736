const defaultSate = {
  isLoading: false,
  ...JSON.parse(window.localStorage.getItem('user'))
}

const authReducer = (state, action) => {
  switch (action.type){
    case 'LOGIN':
    case 'LOGOUT':
      return {
        ...state,
        ...action.payload
      };
    case 'AUTHENTICATE':
    case 'CLEAR_AUTH':
      return {
        ...action.payload
      };
    default:
      return state ?? defaultSate;
  }
}

export default authReducer;
import {Button} from "antd";
import { CloseOutlined } from '@ant-design/icons';
import './less/wrongButton.less';

const WrongButton = ({onClick, ...props}) => {
    return (
        <div className={"button-focus-red"}>
            <Button {...props} type="secondary" onClick={onClick} className='redOutline height60' icon={<CloseOutlined className='wrongButton font25'/>}/>
        </div>
    );
}

export default WrongButton;
import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import PropTypes from 'prop-types';

/**
 * @name      AuthModule      Handles whether user is authenticated or not
 * @param     onAuthSuccess   If user is authenticated, render jsx element
 * @param     onAuthFail      If authentication failed, redirect to different url
 * @param     auth            Authentication state, contains information about authReducer
 * @returns   {JSX.Element}
 */
const AuthModule = ({onAuthSuccess, onAuthFail, auth}) => {
  if (auth.authToken == null)
    // check if given element is a jsx element, otherwise redirect
    return (React.isValidElement(onAuthFail) ?
      onAuthFail :
      <Redirect to={onAuthFail} />
    );

  return onAuthSuccess;
}

const mapStateToProps = state => ({
  auth: state.auth
});

AuthModule.defaultProps = {
  onAuthFail: '/'
}

AuthModule.propTypes = {
  onAuthSuccess: PropTypes.element.isRequired,
}

export default connect(mapStateToProps)(AuthModule);
import {Switch, Route, Redirect} from 'react-router-dom';
import AuthModule from './sharedComponents/auth/AuthModule';

import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import OverviewPage from './pages/Overview';
import QuizOverview from './pages/Quiz/index';
import GamePage from './pages/Game';
import AddQuiz from "./pages/QuizAdd";
import EditQuiz from "./pages/QuizEdit";
import StatisticsPage from "./pages/Satistics";

const App = () => {
  return (
      <>
        <Switch>
          <Route exact path='/login'>
            <AuthModule
              onAuthSuccess={<Redirect to='/overview'/>}
              onAuthFail={<LoginPage />}
            />
          </Route>

          <Route exact path='/register'>
            <AuthModule
              onAuthSuccess={<Redirect to='/overview'/>}
              onAuthFail={<RegisterPage />}
            />
          </Route>

          <Route exact path='/overview'>
            <AuthModule onAuthSuccess={<OverviewPage />} />
          </Route>

          <Route exact path='/quiz'>
            <AuthModule onAuthSuccess={<QuizOverview />} />
          </Route>

          <Route exact path='/addQuiz'>
            <AuthModule onAuthSuccess={<AddQuiz />} />
          </Route>

          <Route exact path='/editQuiz/:id'>
            <AuthModule onAuthSuccess={<EditQuiz />} />
          </Route>

          <Route exact path='/game'>
            <AuthModule onAuthSuccess={<GamePage />} />
          </Route>

          <Route exact path='/statistic'>
            <AuthModule onAuthSuccess={<StatisticsPage />} />
          </Route>

          {/* Default will redirect to '/', so this will redirect to default page depending of auth state */}
          <Route exact path='/'>
            <AuthModule
              onAuthSuccess={<Redirect to='/overview'/>}
              onAuthFail={<Redirect to='/login' />}
            />
          </Route>

          <Redirect to='/' />
        </Switch>
      </>
  );
}

export default App;
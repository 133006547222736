import {Card} from 'antd';
import {useHistory} from 'react-router-dom';
import './cardStyles.less';

const {Meta} = Card;

const QuizContainer = ({quizTitle = "", count, route}) => {
    const history = useHistory();

    function onRouteChange() {
        history.push(route);
    }

    return (
        <>
            <Card className='card-less' onClick={onRouteChange}>
                <Meta
                    title={<p className='content'>{quizTitle}</p>}
                    description={<p className='content'>{count} Cards</p>}
                />
            </Card>
        </>
    );
}

export default QuizContainer;

import {Button} from "antd";
import { EditOutlined } from '@ant-design/icons';
import '../index.less';

const EditButton = ({onClick}) => {
    return (
        <div className={'height100 width100'}>
            <Button onClick={onClick} type="text" shape={"circle"} className={'height100 floatright'} icon={<EditOutlined className={'font25'}/>}/>
        </div>
    );
}

export default EditButton;
import {Button} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import '../sharedComponents/less/addButton.less';
import '../index.less'

const AddButton = ({onClick}) => {
    return (
        <div className={'height100'}>
            <Button type="text" shape={"circle"} onClick={onClick} className={'width100 height100'} icon={<PlusOutlined  className='font25' />}/>
        </div>
    );
}

export default AddButton;